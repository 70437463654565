<script setup lang="ts">
const summaryStore = useSummaryStore();
const { openCheckpointsSummary, summaryPending } = storeToRefs(summaryStore);

const { t } = useI18n();

await useLazyAsyncData(() => summaryStore.fetch());

useSeoMeta({
  title: t('home.page_title'),
  ogTitle: t('home.page_title'),
});
</script>

<template>
  <div class="row g-4">
    <div
      v-if="summaryPending"
      class="text-center"
    >
      <div
        class="spinner-border text-secondary"
        role="status"
      >
        <span class="visually-hidden">{{ t('loading_') }}</span>
      </div>
    </div>
    <div
      v-for="summary in openCheckpointsSummary"
      v-else-if="openCheckpointsSummary.length > 0"
      :key="summary.code"
      class="col-12 col-md-6 col-lg-4 col-xl-3"
    >
      <div class="card bg-body-secondary">
        <NuxtLink :to="{ name: 'checkpoint-code', params: { code: summary.code } }">
          <img
            class="card-img-top"
            :src="'/images/checkpoints/' + summary.code + '.jpg'"
            :alt="summary.name"
          >
        </NuxtLink>
        <div class="card-body position-relative">
          <div class="position-absolute top-0 end-0 pt-2 pe-3">
            <i class="cil-arrow-thick-to-right icon icon-md align-top mt-2" />
            <i
              class="icon icon-xxl"
              :class="'cif-' + summary.countryCode"
            />
          </div>
          <h5 class="card-title">
            <NuxtLink
              class="h5 link-underline link-underline-opacity-0"
              :to="{ name: 'checkpoint-code', params: { code: summary.code } }"
            >
              {{ summary.name }}
            </NuxtLink>
          </h5>
          <div
            v-if="summary.carsQueues"
            v-tooltip="$t('home.tooltip_queues_total')"
            class="d-inline-block me-1"
          >
            <i class="cil-car icon icon-lg" />
            <span class="h5 ms-1">{{ summary.carsQueues.total }}</span>
          </div>
          <div
            v-if="summary.carsQueues?.live.duration"
            v-tooltip="$t('home.tooltip_queue_duration')"
            class="d-inline-block float-end"
          >
            <i class="cil-hourglass icon icon-lg" />
            <FormattedQueueDuration
              class="ms-1"
              :duration="summary.carsQueues.live.duration"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="text-center"
    >
      <h3>{{ $t('error.no_data_loaded') }}</h3>
      <button
        class="btn btn-primary"
        @click="summaryStore.fetch()"
      >
        {{ $t('error.try_again') }}
      </button>
    </div>
  </div>
</template>
